.icon-button {
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  user-select: none;
  outline: none;
  border: none;
  color: var(--black);

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.primary {
    background-color: var(--primary);
    color: white;

    path {
      fill: white !important;
    }
  }

  &.danger {
    color: rgba($color: red, $alpha: 0.8);
    border-color: rgba($color: red, $alpha: 0.5);
    background-color: rgba($color: red, $alpha: 0.05);

    &:hover {
      border-color: red;
      background-color: rgba($color: red, $alpha: 0.1);
    }

    path {
      fill: red !important;
    }
  }

  &:hover,
  &:focus {
    border-color: var(--primary);
  }
}

.shadow {
  box-shadow: var(--card-shadow);
}

.border {
  border: var(--border-in-light);
}

.icon-button-icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-loading-icon {
  width: 40px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white;
  stroke: white;
}

@media only screen and (max-width: 600px) {
  .icon-button {
    padding: 16px;
  }
}

.icon-button-text {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 5px;
  }
}
