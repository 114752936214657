.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .auth-logo {
    transform: scale(1.4);
  }

  .auth-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 2;
  }

  .auth-tips {
    font-size: 14px;
  }

  .auth-input {
    margin: 3vh 0;
  }

  .auth-actions {
    display: flex;
    justify-content: center;
    flex-direction: column;

    button:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
