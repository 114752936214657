@import "../styles/animation.scss";

.card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  padding: 10px;
}

.popover {
  position: relative;
  z-index: 2;
}

.popover-content {
  position: absolute;
  width: 350px;
  animation: slide-in 0.3s ease;
  right: 0;
  top: calc(100% + 10px);
}
@media screen and (max-width: 600px) {
  .popover-content {
    width: auto;
  }
}
.popover-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  border-bottom: var(--border-in-light);
  padding: 10px 20px;
  animation: slide-in ease 0.6s;

  .list-header {
    display: flex;
    align-items: center;

    .list-icon {
      margin-right: 10px;
    }

    .list-item-title {
      font-size: 14px;
      font-weight: bolder;
    }

    .list-item-sub-title {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.list {
  border: var(--border-in-light);
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  margin-bottom: 20px;
  animation: slide-in ease 0.3s;
  background: var(--white);
}

.list .list-item:last-child {
  border: 0;
}

.modal-container {
  box-shadow: var(--card-shadow);
  background-color: var(--white);
  border-radius: 12px;
  width: 80vw;
  max-width: 900px;
  min-width: 300px;
  animation: slide-in ease 0.3s;

  --modal-padding: 20px;

  &-max {
    width: 95vw;
    max-width: unset;
    height: 95vh;
    display: flex;
    flex-direction: column;

    .modal-content {
      max-height: unset !important;
      flex-grow: 1;
    }
  }

  .modal-header {
    padding: var(--modal-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border-in-light);

    .modal-title {
      font-weight: bolder;
      font-size: 16px;
    }

    .modal-header-actions {
      display: flex;

      .modal-header-action {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 20px;
        }

        &:hover {
          filter: brightness(1.2);
        }
      }
    }
  }

  .modal-content {
    max-height: 40vh;
    padding: var(--modal-padding);
    overflow: auto;
  }

  .modal-footer {
    padding: var(--modal-padding);
    display: flex;
    justify-content: flex-end;
    border-top: var(--border-in-light);
    box-shadow: var(--shadow);

    .modal-actions {
      display: flex;
      align-items: center;

      .modal-action {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .modal-container {
    width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .modal-content {
      max-height: 50vh;
    }
  }
}

.show {
  opacity: 1;
  transition: all ease 0.3s;
  transform: translateY(0);
  position: fixed;
  left: 0;
  bottom: 0;
  animation: slide-in ease 0.6s;
  z-index: 99999;
}

.hide {
  opacity: 0;
  transition: all ease 0.3s;
  transform: translateY(20px);
}

.toast-container {
  position: fixed;
  bottom: 5vh;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  pointer-events: none;

  .toast-content {
    max-width: 80vw;
    word-break: break-all;
    font-size: 14px;
    background-color: var(--white);
    box-shadow: var(--card-shadow);
    border: var(--border-in-light);
    color: var(--black);
    padding: 10px 20px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    pointer-events: all;

    .toast-action {
      padding-left: 20px;
      color: var(--primary);
      opacity: 0.8;
      border: 0;
      background: none;
      cursor: pointer;
      font-family: inherit;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.input {
  border: var(--border-in-light);
  border-radius: 10px;
  padding: 10px;
  font-family: inherit;
  background-color: var(--white);
  color: var(--black);
  resize: none;
  min-width: 50px;
}

.select-with-icon {
  position: relative;
  max-width: fit-content;

  .select-with-icon-select {
    height: 100%;
    border: var(--border-in-light);
    padding: 10px 35px 10px 10px;
    border-radius: 10px;
    appearance: none;
    cursor: pointer;
    background-color: var(--white);
    color: var(--black);
    text-align: center;
  }

  .select-with-icon-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.modal-input {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: var(--border-in-light);
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.03);
  background-color: var(--white);
  color: var(--black);
  font-family: inherit;
  padding: 10px;
  resize: none;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border: 1px solid var(--primary);
  }
}

.selector {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &-content {
    .list {
      max-height: 90vh;
      overflow-x: hidden;
      overflow-y: auto;

      .list-item {
        cursor: pointer;
        background-color: var(--white);

        &:hover {
          filter: brightness(0.95);
        }

        &:active {
          filter: brightness(0.9);
        }
      }
    }
  }
}
